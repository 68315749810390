import * as React from "react"
import { Helmet } from 'react-helmet'

const Careers = () => {

  return (
    <div class="container careers"> 
    <Helmet>
      <title>Careers at Tinyman | Tinyman</title>
      <meta name="description" content="Tinyman AMM is looking for developers, designers, crypto enthusiasts to work in DeFi platform on Algorand blockchain. Apply with your CV." />
    </Helmet>
    <div class="careers_bg">
    </div>
    <div class="sub_intro">
      <div class="page_title">
        <h1>Careers at Tinyman</h1>
      </div>
      <svg class="page_title_svg" width="1042" height="126" viewBox="0 0 1042 126" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Careers at Tinyman</title>
        <g clip-path="url(#clip0_5549_106608)">
          <path d="M6.88977 105.157C4.75571 94.8885 2.65214 84.6501 0.518081 74.3812C-2.22571 61.187 6.00566 50.461 21.2489 47.4443C36.4922 44.4581 48.1686 51.2837 50.6075 64.5389C45.4553 65.4835 40.3335 66.4586 35.1813 67.4641C34.0838 61.705 29.9376 59.1759 23.7793 60.3947C17.5906 61.6136 14.7248 65.5139 15.8833 71.2731C17.9564 81.542 19.999 91.8109 22.0721 102.08C23.2306 107.839 27.3158 110.338 33.3521 109.149C39.4189 107.961 42.2237 104.121 41.1262 98.3318C46.1869 97.3568 51.2782 96.3817 56.339 95.437C58.7779 108.692 50.638 119.174 35.8825 122.069C21.127 124.964 9.60307 118.291 6.85928 105.188L6.88977 105.157Z" fill="black"/>
          <path d="M69.113 39.5522C75.698 38.3943 82.2527 37.2364 88.8378 36.1394C99.2337 59.3892 109.447 82.7 119.477 106.072C114.325 106.894 109.172 107.748 104.02 108.631C101.916 103.329 99.8129 98.0272 97.6789 92.7251C91.795 93.7307 85.911 94.7363 80.0271 95.7723C79.8137 101.47 79.6003 107.169 79.4174 112.867C74.2652 113.781 69.1434 114.726 63.9912 115.67C65.5155 90.3179 67.2228 64.9351 69.113 39.5522V39.5522ZM93.0144 81.1764C89.1731 71.2122 85.3013 61.248 81.4295 51.2838C81.0942 61.9793 80.7588 72.6748 80.4844 83.3399C84.6611 82.6086 88.8378 81.8773 93.0449 81.1764H93.0144Z" fill="black"/>
          <path d="M112.831 32.1779C120.971 30.8677 129.141 29.6183 137.281 28.3995C153.439 25.9922 165.085 33.214 166.944 46.8652C168.255 56.4029 164.231 65.1482 156.731 69.597C162.89 78.9518 168.987 88.3371 175.054 97.7223C169.566 98.4841 164.048 99.2764 158.561 100.069C153.225 91.4147 147.86 82.7912 142.464 74.1678C140.086 74.5334 137.708 74.8991 135.299 75.2648C136.702 84.4672 138.135 93.7001 139.537 102.902C134.507 103.664 129.507 104.457 124.477 105.249C120.574 80.902 116.703 56.5247 112.8 32.1779H112.831ZM142.159 60.8822C148.927 59.8766 152.433 55.7934 151.579 49.76C150.665 43.5438 146.092 40.4053 139.263 41.4413C136.305 41.8984 133.318 42.325 130.361 42.7821C131.367 49.2725 132.342 55.7325 133.348 62.2229C136.305 61.7659 139.232 61.3392 142.189 60.8822H142.159Z" fill="black"/>
          <path d="M172.707 23.3717C188.346 21.2387 204.016 19.2885 219.686 17.4602C220.205 21.8176 220.692 26.1751 221.211 30.5325C210.723 31.7514 200.236 33.0617 189.749 34.4024C190.45 39.8873 191.181 45.4027 191.883 50.8875C201.09 49.6991 210.327 48.5412 219.534 47.4747C220.022 51.6493 220.509 55.7935 220.997 59.9681C211.821 61.065 202.644 62.1925 193.468 63.3809C194.261 69.4752 195.053 75.5695 195.846 81.6638C206.15 80.3231 216.424 79.0737 226.729 77.8549C227.247 82.2123 227.735 86.5698 228.253 90.9272C213.04 92.6945 197.827 94.6143 182.645 96.6863C179.322 72.2481 176.03 47.8099 172.707 23.3717V23.3717Z" fill="black"/>
          <path d="M231.18 16.1194C246.85 14.352 262.55 12.7371 278.251 11.2744C278.647 15.6623 279.074 20.0198 279.47 24.4077C268.953 25.3828 258.435 26.4493 247.947 27.5462C248.527 33.0616 249.106 38.577 249.716 44.0923C258.953 43.1172 268.19 42.1726 277.428 41.2889C277.824 45.4635 278.22 49.6381 278.617 53.8127C269.41 54.6964 260.233 55.6106 251.057 56.5857C251.697 62.7104 252.368 68.8048 253.008 74.9296C263.313 73.8326 273.647 72.7965 283.952 71.8214C284.348 76.2094 284.775 80.5668 285.171 84.9547C269.928 86.3869 254.685 87.9409 239.472 89.6778C236.728 65.1786 233.954 40.649 231.21 16.1499L231.18 16.1194Z" fill="black"/>
          <path d="M288.159 10.3603C296.36 9.629 304.56 8.92815 312.761 8.25777C329.011 6.9475 340.108 14.9615 341.053 28.7042C341.724 38.3028 337.12 46.7434 329.346 50.7047C334.834 60.4556 340.291 70.237 345.656 80.0184C340.138 80.4145 334.59 80.8106 329.072 81.2372C324.346 72.2481 319.621 63.259 314.804 54.3003C312.396 54.4832 310.018 54.6965 307.609 54.8793C308.402 64.1731 309.164 73.467 309.957 82.7608C304.896 83.1874 299.866 83.614 294.805 84.0711C292.61 59.511 290.384 34.9509 288.189 10.3603H288.159ZM315.383 41.0147C322.212 40.4662 325.962 36.6268 325.505 30.563C325.047 24.2858 320.688 20.873 313.798 21.4215C310.81 21.6653 307.823 21.909 304.835 22.1528C305.384 28.6737 305.932 35.2251 306.481 41.746C309.438 41.5023 312.396 41.2585 315.383 41.0147Z" fill="black"/>
          <path d="M348.553 59.4805C353.674 59.1453 358.827 58.8102 363.948 58.475C364.253 63.4418 368.643 66.3062 375.289 65.91C381.722 65.5444 385.38 62.4058 385.106 57.3475C384.862 52.9901 381.814 49.9125 376.814 49.0592C374.253 48.6326 371.662 48.206 369.101 47.7794C356.144 45.616 348.156 37.7238 347.364 26.1141C346.48 13.1637 355.656 4.08319 371.204 3.19951C386.844 2.31584 397.118 9.9642 397.697 22.6099C392.484 22.8537 387.301 23.1279 382.088 23.4022C381.844 18.74 378.125 15.9976 371.753 16.3632C365.808 16.6984 362.638 19.6542 362.942 24.8953C363.186 28.8261 366.083 31.9037 371.326 32.7265C373.887 33.1531 376.448 33.5797 378.978 34.0063C392.514 36.3221 399.923 43.9095 400.471 55.8544C401.051 68.9267 391.295 78.1901 375.747 79.0738C360.077 79.9879 349.467 72.4005 348.553 59.4501V59.4805Z" fill="black"/>
          <path d="M478.883 0.304808C485.529 0.213393 492.175 0.121978 498.821 0.0610352C505.345 24.6821 511.686 49.3031 517.875 73.9851C512.662 73.9851 507.479 73.9851 502.266 74.046C501.047 68.4697 499.797 62.9239 498.547 57.3476C492.602 57.4086 486.627 57.4695 480.682 57.5609C479.554 63.1677 478.456 68.744 477.359 74.3508C472.145 74.4422 466.963 74.5336 461.75 74.6555C467.268 49.8516 472.969 25.0782 478.852 0.304808H478.883ZM495.772 45.2199C493.577 34.7682 491.352 24.3164 489.126 13.8647C487.084 24.3774 485.072 34.8596 483.09 45.3723C487.328 45.3113 491.535 45.2504 495.772 45.2199Z" fill="black"/>
          <path d="M539.765 13.7732C533.332 13.7122 526.869 13.6817 520.436 13.6817C520.436 9.111 520.436 4.57074 520.436 0C538.637 0 556.837 0.213301 575.037 0.609432C574.946 5.18017 574.855 9.72044 574.733 14.2912C568.3 14.1388 561.867 14.0474 555.404 13.956C555.13 34.0672 554.825 54.148 554.55 74.2593C549.459 74.1983 544.337 74.1374 539.246 74.0764C539.399 53.9652 539.582 33.8844 539.734 13.7732H539.765Z" fill="black"/>
          <path d="M656.68 17.3687C650.248 17.0336 643.815 16.6984 637.382 16.3937C637.596 11.8229 637.809 7.28265 638.023 2.71191C656.223 3.56512 674.393 4.57068 692.563 5.82002C692.258 10.3603 691.953 14.931 691.648 19.4713C685.216 19.0447 678.783 18.6181 672.35 18.2524C671.161 38.3332 669.942 58.3835 668.753 78.4643C663.662 78.1596 658.57 77.8853 653.479 77.6111C654.546 57.5303 655.644 37.4495 656.711 17.3992L656.68 17.3687Z" fill="black"/>
          <path d="M696.648 66.5803C701.587 66.9155 706.526 67.2812 711.495 67.6773C712.684 52.1672 713.873 36.6877 715.062 21.1776C710.032 20.7815 705.002 20.4159 699.941 20.0502C700.276 15.5099 700.581 10.9392 700.916 6.39893C716.282 7.4959 731.647 8.71477 747.012 10.0555C746.616 14.5958 746.219 19.136 745.793 23.6763C740.762 23.2192 735.732 22.7926 730.702 22.3965C729.421 37.8761 728.141 53.3861 726.86 68.8657C731.799 69.2618 736.738 69.6884 741.677 70.1455L740.457 83.7663C725.549 82.456 710.611 81.2676 695.703 80.2011C696.038 75.6608 696.343 71.0901 696.679 66.5498L696.648 66.5803Z" fill="black"/>
          <path d="M756.005 10.9089C761.554 11.4269 767.102 11.9449 772.651 12.4934C778.413 28.5215 784.083 44.5495 789.571 60.608C791.217 45.3113 792.864 30.045 794.51 14.7483C799.388 15.2663 804.266 15.8148 809.143 16.3633C806.369 40.8625 803.595 65.3921 800.821 89.8912C795.79 89.3123 790.73 88.7638 785.699 88.2153C779.998 72.1568 774.145 56.0983 768.169 40.0397C766.676 55.3365 765.151 70.6332 763.657 85.9299C758.841 85.4729 754.024 85.0158 749.237 84.5587C751.493 59.9986 753.78 35.4385 756.036 10.8784L756.005 10.9089Z" fill="black"/>
          <path d="M827.436 66.4889C822.192 49.8514 816.826 33.2139 811.308 16.6069C816.765 17.2164 822.253 17.8563 827.71 18.5266C831.063 29.1308 834.356 39.7653 837.588 50.3695C843.197 40.8623 848.837 31.3552 854.538 21.8785C859.965 22.5794 865.391 23.3107 870.818 24.0725C861.367 38.7902 852.008 53.569 842.801 68.4086C841.673 77.1235 840.545 85.8384 839.417 94.5533C834.356 93.9133 829.295 93.2734 824.204 92.6335C825.271 83.9187 826.338 75.2038 827.405 66.4889H827.436Z" fill="black"/>
          <path d="M927.554 32.5436C923.651 56.8904 919.749 81.2372 915.816 105.614C911.091 104.853 906.396 104.121 901.67 103.39C903.652 90.6224 905.603 77.8853 907.585 65.1177C903.835 71.273 900.085 77.4282 896.396 83.614C893.47 83.1874 890.543 82.7303 887.616 82.3037C885.878 75.3257 884.11 68.3477 882.311 61.3392C880.482 74.1068 878.623 86.8744 876.793 99.6725C872.068 99.0021 867.343 98.3317 862.617 97.6614C866.001 73.2231 869.385 48.7849 872.769 24.3772C876.671 24.9257 880.543 25.4742 884.445 26.0227C888.165 37.7542 891.793 49.4858 895.36 61.2173C901.792 50.9789 908.286 40.7404 914.841 30.5629C919.078 31.2333 923.316 31.8732 927.523 32.5741L927.554 32.5436Z" fill="black"/>
          <path d="M947.705 35.865C954.29 36.962 960.875 38.1199 967.429 39.2778C969.411 64.6606 971.179 90.0435 972.795 115.426C967.673 114.482 962.521 113.537 957.369 112.623C957.155 106.925 956.972 101.227 956.759 95.5284C950.875 94.4923 944.991 93.4868 939.077 92.4812C936.973 97.7833 934.87 103.085 932.766 108.387C927.614 107.534 922.462 106.681 917.31 105.858C927.248 82.4865 937.4 59.1453 947.674 35.865H947.705ZM956.21 83.0959C955.936 72.4309 955.662 61.7354 955.326 51.0398C951.424 61.004 947.522 70.9378 943.65 80.9325C947.827 81.6333 952.003 82.3646 956.21 83.0959V83.0959Z" fill="black"/>
          <path d="M989.411 43.2393C994.899 44.2448 1000.39 45.2809 1005.87 46.3474C1010.17 62.8325 1014.38 79.3176 1018.4 95.8027C1021.42 80.7193 1024.47 65.6663 1027.49 50.5829C1032.31 51.558 1037.15 52.5331 1041.97 53.5387C1036.97 77.7026 1031.97 101.836 1026.97 126C1021.97 124.964 1017 123.958 1012 122.953C1007.76 106.407 1003.37 89.8913 998.862 73.3757C995.966 88.4896 993.069 103.573 990.173 118.687C985.417 117.773 980.661 116.889 975.875 116.005C980.387 91.75 984.869 67.4946 989.381 43.2393H989.411Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0_5549_106608">
            <rect width="1042" height="126" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <p>Join us on our mission to create a more secure, transparent, and open financial ecosystem for Algorand blockchain.</p>
      <div class="buttons">
        <a href="mailto:careers@tinyman.org" target="_blank" rel="noreferrer">Contact us: careers@tinyman.org</a>
      </div>
    </div>
    <div class="careers_space">
      <div class="links">
        <a href="https://twitter.com/dmt_vision" target="_blank" rel="noreferrer">Art by @dmt_vision</a>
        <a href="https://dartroom.mypinata.cloud/ipfs/Qmcfar5cRREi4dG8xrAEC6AfemJJrucywYw5x1qKpL9Zyy" target="_blank" rel="noreferrer">
          View on IPFS
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M8.67945 2.59202L1.15945 10.08L0.439453 9.36002L7.95945 1.85602H3.25545V0.848022H9.70345V7.29602H8.67945V2.59202Z" fill="black"/>
          </svg>
        </a>
      </div>
    </div>
    <div class="section double">
      <div>
        <h3>Our mission</h3>
        <p>We are builders of a new technology that is constantly evolving. Our aim is simple, to create the best experience for our users as we try to build a decentralized company with our community.</p>
      </div>
      <div>
        <h3>Working at Tinyman</h3>
        <p>Tinyman has a distributed team that works remotely. Constant communication is vital for us to sync on different product areas. Therefore, we look for respect among team members and good communication above all as we build a tiny culture together.</p>
      </div>
    </div>
    <div class="careers_cta_bg"></div>
    <ul class="careers_cta">
      <li class="coming-soon">
        <p><h2>See open positions<span>Coming soon</span></h2></p>
      </li>
      <li>
        <a href="mailto:careers@tinyman.org" target="_blank" rel="noreferrer"><h2>Contact us</h2></a>
      </li>
    </ul>
  </div>
  )
}

export default Careers
